@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
* {
  font-family: "Quicksand";
  color:darkolivegreen;
  background-color: lightgrey;
}
* input {
  background-color: white;
}
* button {
    top:50%;
    background-color:gray;
    color: #fff;
    border:none; 
    border-radius:10px; 
    padding:15px;
    min-height:30px; 
    min-width: 120px;
    font-size: 25px;
    margin-top: 10px;
    margin-left: 5px;
}
* button:hover {
  background-color:darkolivegreen;
  transition: 0.7s;
}
* button:focus {
  outline-color: transparent;
  outline-style:solid;
  transition: 0.7s;
}
* button:active {
  background-color: #ffbf00;
}
* a {
  text-decoration: none;
}

/*Left*/
.left {
  padding: 20px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  font-size: 25px;
  height: 375px;
  width: 400px;
  justify-content: left;
  margin-right: 60px;
  margin-top: 25px;

  overflow: hidden;
  border-radius: 20px;
}

/*Right*/
.right {
  justify-content: right;
  margin-left: 60px;
  margin-top: 25px;
  padding: 20px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  font-size: 25px;
  height: 375px;
  width: 400px;

  overflow: hidden;
  border-radius: 20px;
}

/*Navbar*/
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  border-bottom:3px solid gray;
  justify-content: space-between;
}
.navbar-links a {
  margin-left: 25px;
  text-decoration: none;
  padding: 10px;
  font-size: 20px;
}
.navbar-logo {
  width: 120px;
  height: 80px;
}
.navbar-home {
  color:#f2f2f2;
  background-color:darkolivegreen;
  border-radius: 5px;
}
.content {
  display: flex;
  max-width: 1200px;
  margin: 25px auto;
  padding: 20px;
}

/* option previews */
.option-preview {
  padding: 5px 16px;
  margin: 20px 0;
  border-bottom: 1px solid gray;
  transition: 400ms ease-in-out;
  font-size: 19px;
  min-width: 600px;
}
.option-preview:hover {
  box-shadow: 0rem 0 3rem gray;
}
.options h1 {
  text-shadow: -1rem 0 5rem gray;
}
.options a {
  text-decoration: none;
}

/*ExtendedEuclideanAlgorithem*/
.eea-div label{
  font-size: 25px;
  margin: 10px;
}
.eea-div input{
  font-size: 32px;
  margin-bottom: 10px;
}
.eea-div button:hover{
  box-shadow: 0rem 0rem 0.2rem darkolivegreen;
}
.eea-answer text{
  font-size: 25px;
  max-width: 200;
}
.eea-answer {
  margin-top: 40px;
}
.eea-first input {
  margin-left: 36px;
}

/*Square-and-Multiply*/
.sam-div label{
  font-size: 25px;
  margin: 10px;
}
.sam-div input{
  font-size: 32px;
  margin-bottom: 10px;
}
.sam-div button:hover{
  box-shadow: 0rem 0rem 0.2rem darkolivegreen;
}
.sam-answer text{
  font-size: 40px;
  max-width: 200;
}
.sam-answer {
  margin-top: 40px;
}
.sam-example {
  display: flex;
  margin-left: 310px;;
  margin-top: 10px;
  margin-bottom: 20px;
  float: left;
  font-size: 25px;
}
.sam-first input{
  margin-left: 43px;
}
.sam-third input{
  margin-left: 126px;
}

/*Gaussian Elimination*/
.ge-matrix {
  margin-top: 40px;
  margin-bottom: 40px;
  size: 30px;
}
.ge-matrix input {
  margin-top: 5px;
  max-width: 80px;
}
.rows-columns-div label {
  font-size: 30px;
}
.rows-columns-div input {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 300px;
  margin-top: 35px;
}
.rules {
  font-size: 18px;
}

/*Number Systems*/
.ns-div label{
  font-size: 25px;
  margin: 10px;
}
.ns-div input{
  font-size: 32px;
  margin-bottom: 10px;
}
.ns-div button:hover{
  box-shadow: 0rem 0rem 0.2rem darkolivegreen;
}
.ns-answer text{
  font-size: 40px;
}
.ns-answer {
  margin-top: 40px;
}
.ns-sub {
  font-size: 22px;
}
@media (min-width: 1000px) {
  .ns-input2 {
    margin-left: 25px;
  }
}

/*AlgoDetails*/
.details-div {
  font-size: 20px;
}
.details-div a {
  text-decoration: none;
}
.details-div div {
  padding-bottom: 20px;
  border-bottom: 1px solid gray;
}
.details-div h2 {
  font-size: 30px;
}
.update-text sub {
  font-size: 13px;
}

/*Sorting Algorithms*/
.header {
  border-bottom: 1px solid gray;
}
.sorting-div {
  font-size: 20px;
}
.sorting-div div {
  padding-bottom: 20px;
  border-bottom: 1px solid gray;
}
.sorting-div h2 {
  font-size: 30px;
}
.py-download {
  font-size: 25px;
  font-weight: 600;
}

/*WhatIsAnAlgorithm*/
.wiaa-div p{
  font-size: 20px;
}
.pie {
  font-size: 20px;
}

/*Contact*/
.contact-div {
  font-size: 20px;
}

/*NotFound*/
.doesn-not-exist {
  font-size: 50px;
}
.notfound-div a{
  font-size: 20px;
  text-decoration: none;
}
